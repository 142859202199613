<template>
  <main class="main_v8 help">
    <h2>TEST</h2>
    <label for="file-upload" class="custom-file-upload">Open PDF</label>
    <input id="file-upload" type="file" @change="openDocument" class="btn" />
    <PSPDFKitContainer :pdf-file="pdfFile" @loaded="handleLoaded" />
  </main>
</template>
<script>
import PSPDFKitContainer from '../components/pspdfkit/PSPDFKitContainer';

export default {
  name: 'PSPFDKitView',
  inject: ['apiPath'],
  components: {
    PSPDFKitContainer,
  },
  data() {
    return {
      pdfFile: this.pdfFile || "/pspdfkit-web-demo.pdf",
    };
  },
  methods: {
    handleLoaded(instance) {
      console.log("PSPDFKit has loaded: ", instance);
    },
    openDocument(event) {
      if (this.pdfFile && this.pdfFile.startsWith('blob:')) {
        window.URL.revokeObjectURL(this.pdfFile);
      }
      this.pdfFile = window.URL.createObjectURL(event.target.files[0]);
    }
  }
};
</script>
<style lang="scss" scoped>
main {
  h2 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #ccc;
    border-radius: 4px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background: #2dc26b;
    color: #ffffff;
    font: inherit;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>